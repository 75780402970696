import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import AWS from 'aws-sdk';
import { awsConfig } from './aws-config';

AWS.config.update({
  region: awsConfig.region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsConfig.identityPoolId
  })
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
