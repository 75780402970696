// skyboxSettings.js
import SunCalc from 'suncalc';

export function setSkyMaterialProperties(skyMaterial, date, latitude, longitude) {
  const sunPosition = SunCalc.getPosition(date, latitude, longitude);

  // Adjust the luminance and other properties based on time of day
  const hours = date.getHours();
  let timeOfDay;
  if (hours >= 6 && hours < 12) {
    timeOfDay = 'morning';
    skyMaterial.luminance = 0.8;
    skyMaterial.turbidity = 10;
    skyMaterial.inclination = 0.2;
  } else if (hours >= 12 && hours < 18) {
    timeOfDay = 'noon';
    skyMaterial.luminance = 1.0;
    skyMaterial.turbidity = 10;
    skyMaterial.inclination = 0.5;
  } else if (hours >= 18 && hours < 21) {
    timeOfDay = 'evening';
    skyMaterial.luminance = 0.6;
    skyMaterial.turbidity = 15;
    skyMaterial.inclination = 0.38;
  } else {
    timeOfDay = 'night';
    skyMaterial.luminance = 0.01;
    skyMaterial.turbidity = 0;
    skyMaterial.inclination = 0.76;
  }
  console.log(timeOfDay);

  // Convert azimuth and altitude to directional vector for the light
  const azimuth = sunPosition.azimuth + Math.PI; // SunCalc returns azimuth in range [-PI, PI], we need [0, 2PI]
  const altitude = 1 - (sunPosition.altitude / (Math.PI / 2));
  const skyAzimuth = azimuth / (2 * Math.PI);

  skyMaterial.azimuth = skyAzimuth;
  console.log(skyAzimuth);
  console.log(altitude);
}