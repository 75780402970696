// actor.js
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { ActionManager, ExecuteCodeAction } from "@babylonjs/core/Actions";

export function createActor(scene) {
    const actorHeight = 1.8;
    const actor = MeshBuilder.CreateCapsule("actor", { height: actorHeight, radius: 0.5 }, scene);
    actor.position.y = actorHeight / 2;
    actor.checkCollisions = true;
    return actor;
}

export function setupActorMovement(scene, actor, camera) {
    const inputMap = {};
    scene.actionManager = new ActionManager(scene);
    scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyDownTrigger, function (evt) {
        inputMap[evt.sourceEvent.key] = evt.sourceEvent.type === "keydown";
    }));
    scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyUpTrigger, function (evt) {
        inputMap[evt.sourceEvent.key] = evt.sourceEvent.type === "keyup";
    }));

    scene.onBeforeRenderObservable.add(() => {
        const speed = 0.1;
        const forward = new Vector3(
            Math.sin(camera.rotation.y),
            0,
            Math.cos(camera.rotation.y)
        );
        const right = new Vector3(
            Math.sin(camera.rotation.y + Math.PI / 2),
            0,
            Math.cos(camera.rotation.y + Math.PI / 2)
        );

        if (inputMap["w"] || inputMap["ArrowUp"]) {
            actor.moveWithCollisions(forward.scale(speed));
        }
        if (inputMap["s"] || inputMap["ArrowDown"]) {
            actor.moveWithCollisions(forward.scale(-speed));
        }
        if (inputMap["a"] || inputMap["ArrowLeft"]) {
            actor.moveWithCollisions(right.scale(-speed));
        }
        if (inputMap["d"] || inputMap["ArrowRight"]) {
            actor.moveWithCollisions(right.scale(speed));
        }
    });
}