<template>
    <v-navigation-drawer v-model="localRightDrawer" right app class="drawer-right transparent-drawer" @update:localRightDrawer="emitUpdate">
      <v-treeview
        :items="treeItems"
        activatable
        item-key="id"
        open-on-click
        dense
        @update:active="updateActive"   
      >
      <template v-slot:label="{ item }">
      <div v-if="item.isJpgFilesLevel">
        <v-img :src="getTextureUrl(item, item.name)" width="50" height="50"></v-img>
      </div>
      <div v-else>
        {{ item.name }}
      </div>
    </template>
    <template v-slot:prepend="{ item, active }">
      <div>
        <div v-if="active && item.isJpgFilesLevel" class="image-buttons">
          <v-row>
            <v-col
              v-for="(file, index) in item.children"
              :key="index"
              cols="6"
            >
              <v-btn icon @click="applyTexture(item, file.name)">
                <v-img
                  :src="getTextureUrl(item, file.name)"
                  width="50"
                  height="50"
                ></v-img>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
      </v-treeview>
    </v-navigation-drawer>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      rightDrawer: Boolean
    },
    data() {
      return {
        localRightDrawer: this.rightDrawer,
        treeItems: [],
        activeNodeIds: []
      };
    },
    watch: {
      rightDrawer(newVal) {
        this.localRightDrawer = newVal;
      }
    },
    methods: {
      emitUpdate(value) {
        this.$emit('update:rightDrawer', value);
      },
      loadTextures() {
        axios
          .get('https://q69yfo7sj3.execute-api.eu-north-1.amazonaws.com/default/LoadTextures?file=Glass_structure.json')
          .then(response => {
            this.treeItems = this.transformData(response.data);
          })
          .catch(error => {
            console.error('Ошибка при загрузке данных текстур:', error);
          });
      },
 transformData(data) {
  const transform = (obj, idPrefix = '', parentPath='') =>
    Object.keys(obj).map((key, index) => {
      const id = `${idPrefix}${index}`;
      const value = obj[key];
      const currentPath = parentPath ? `${parentPath}/${key}` : key;

      const children = 'jpg_files' in value 
        ? value.jpg_files.map((file, fileIndex) => ({
            id: `${id}-file${fileIndex}`,
            name: file,
            children: null,
            isJpgFilesLevel: true,
            path: currentPath
          }))
        : (typeof value === 'object' ? transform(value, `${id}-`, currentPath) : null);
      
      return {
        id,
        name: key,
        children: children,
        isJpgFilesLevel: false,
        path: currentPath
      };
    });

  return transform(data);
},
    // Function to get texture URL
  getTextureUrl(item, file) {
    let path = item.path;
    return `https://glassdatabase.s3.eu-north-1.amazonaws.com/Glass/${path}/${file}`;
  },


      applyTexture(item, file) {
        this.$emit('apply-texture', { item, file });
        console.log(`Applying texture ${file} from ${item.name}`);
      },
      updateActive(activeNodeIds) {
        this.activeNodeIds = activeNodeIds;
      }
    },
    mounted() {
      this.loadTextures();
    }
  };
  </script>
  
  <style scoped>
  .drawer-right {
    max-width: 300px;
  }
  .transparent-drawer {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .image-buttons {
    display: flex;
    flex-wrap: wrap;
  }
  </style>