<template>
  <v-container v-if="dialog" class="overlay-container" fill-width fill-height>
    <v-dialog v-model="dialog" width="60%">
      <v-card>
        <v-card-title class="headline">
          <div class="dialog-header">
            <div class="emblem"></div>
            <span>Create Project</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="handleSubmit">
            <v-text-field
              v-model="name"
              label="Project Name"
              required
            ></v-text-field>
            <v-select
              v-model="type"
              :items="projectTypes"
              label="Project Type"
              required
            ></v-select>
            <v-btn type="submit" color="primary">Create Project</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      type: 'one-time',
      projectTypes: ['one-time', 'recurring'],
      dialog: true,
    };
  },
  methods: {
    checkLoginStatus() {
      const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.6cn1t90kbpb55s5chp6orkrt44.LastAuthUser');
      const tokenKey = `CognitoIdentityServiceProvider.6cn1t90kbpb55s5chp6orkrt44.${lastAuthUser}.idToken`;
      const token = localStorage.getItem(tokenKey);
      if (token) {
        this.isLoggedIn = true;
        this.username = lastAuthUser; // Сохраняем имя пользователя
      } else {
        this.isLoggedIn = false;
      }
    },
    handleSubmit() {
      const tokenKey = `CognitoIdentityServiceProvider.6cn1t90kbpb55s5chp6orkrt44.${this.username}.idToken`;
      const token = localStorage.getItem(tokenKey);
      axios
        .post(`https://q69yfo7sj3.execute-api.eu-north-1.amazonaws.com/default/vitraView-CreateProject`, { name: this.name, type: this.type, username: localStorage.getItem('CognitoIdentityServiceProvider.6cn1t90kbpb55s5chp6orkrt44.LastAuthUser') }, {
          headers: {
            Authorization: `Bearer ${token}` // Предполагается, что токен хранится в localStorage
          }
        })
        .then((response) => {
          // Вызываем метод для открытия нового проекта на вкладке
          this.$emit('project-created', {
            projectId: response.data.projectId,
            name: this.name,
          });
          this.dialog = false; // Закрываем диалог
        })
        .catch((error) => {
          console.error('Произошла ошибка при создании проекта!', error);
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push('/project-list'); // Предполагается, что у вас есть маршрут списка проектов
    },
  },
};
</script>

<style scoped>
.v-card-title {
  text-align: center;
}
.v-dialog {
  overflow: hidden;
}
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.dialog-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background: linear-gradient(90deg, rgba(15, 39, 69, 0) 0%, #0F2745 23%, #32455A 65%, #92B5DE 100%);
  border-radius: 5px;
}
.emblem {
  width: 30px;
  height: 30px;
  background: url('/public/logo.png') no-repeat center center;
  background-size: contain;
  margin-right: 10px;
}
.v-dialog__content--active .v-card {
  background: linear-gradient(180deg, #0F2745 0%, #FFFFFF 100%);
}
</style>