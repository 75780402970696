<template>
  <v-app>
    <LeftNavigationDrawer :leftDrawer.sync="leftDrawer" @update:leftDrawer="leftDrawer = $event" :skyMaterial="skyMaterial"/>

    <RightNavigationDrawer
      :rightDrawer.sync="rightDrawer"
      @update:rightDrawer="rightDrawer = $event"
      @apply-texture="applyTexture"
    />

    <v-container class="no-padding" fluid>
      <v-row>
        <v-col>
          <div class="tabbar">
            <img src="/logo.png" alt="Logo" class="logo" @click="goToProjectList"/>
            <v-tabs v-model="activeTab">
              <v-tab v-for="(tab, index) in tabs" :key="tab.id" @mouseover="showCloseIcon(index)" @mouseleave="hideCloseIcon(index)">
                {{ tab.name }}
                <v-icon
                  small
                  class="ml-2 close-icon"
                  v-if="tab.showCloseIcon"
                  @click.stop="closeTab(index)"
                >
                  mdi-close
                </v-icon>
              </v-tab>
              <v-icon small class="ml-2" @click="addTab">mdi-plus</v-icon>
            </v-tabs>
            <v-icon small class="ml-2 user-avatar" @click="logout">mdi-account</v-icon>
          </div>

          <v-tabs-items v-model="activeTab" class="full-width">
            <v-tab-item v-for="(tab) in tabs" :key="tab.id">
              <div :id="'container-' + tab.id" class="canvas-container">
                <canvas :ref="'canvas-' + tab.id" :id="'canvas-' + tab.id" class="renderCanvas"></canvas>
                <v-btn class="toggle-treeview-btn" @click="toggleRightDrawer">
                  Показать дерево
                </v-btn>
                <v-btn class="toggle-leftdrawer-btn" @click="toggleLeftDrawer">
                  Открыть левую панель
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>

    <!-- Включение компонента создания проекта -->
    <create-project-dialog v-if="showCreateProjectDialog" @project-created="updateTabWithProject"/>
  </v-app>
</template>

<script>
import { initializeBabylon } from '@/babylon';
import LeftNavigationDrawer from './LeftNavigationDrawer.vue';
import RightNavigationDrawer from './RightNavigationDrawer.vue';
import CreateProjectDialog from './CreateProject.vue';

export default {
  components: {
    LeftNavigationDrawer,
    RightNavigationDrawer,
    CreateProjectDialog,
  },
  data() {
    return {
      tabs: [
        { id: 0, name: 'New Tab', isProject: false, showCloseIcon: false }
      ],
      activeTab: 0,
      leftDrawer: false,
      rightDrawer: false,
      projectDrawer: false,
      treeItems: [],
      skyMaterial: null,
      showCreateProjectDialog: false, // Управление видимостью диалога создания проекта
    };
  },
  methods: {
    addTab() {
      const newId = this.tabs.length;
      this.tabs.push({ id: newId, name: 'New Tab', isProject: false, showCloseIcon: false });
      this.$nextTick(() => {
        const canvasId = `canvas-${newId}`;
        const canvas = this.$refs[canvasId][0];
        const sceneData = initializeBabylon(canvas);
        this.skyMaterial = sceneData.skyMaterial;
      });
      this.activeTab = newId; // Устанавливаем активную вкладку на новую вкладку
    },
    closeTab(index) {
      this.tabs.splice(index, 1);
      if (this.activeTab >= this.tabs.length) {
        this.activeTab = this.tabs.length - 1;
      }
    },
    updateTabWithProject(project) {
      this.tabs[this.activeTab].name = project.name; // Изменяем имя текущей вкладки
      this.tabs[this.activeTab].isProject = true; // Обозначаем, что это проектная вкладка
      // Логика загрузки проекта в Babylon.js
      this.showCreateProjectDialog = false; // Закрываем диалог создания проекта
      this.$router.push(`/upload-image/${project.projectId}`); // Открываем диалог загрузки изображений
    },
    showCloseIcon(index) {
      this.tabs[index].showCloseIcon = true;
    },
    hideCloseIcon(index) {
      this.tabs[index].showCloseIcon = false;
    },
    toggleRightDrawer() {
      this.rightDrawer = !this.rightDrawer;
    },
    toggleLeftDrawer() {
      this.leftDrawer = !this.leftDrawer;
    },
    goToProjectList() {
      this.$router.push({
        path: '/projectList'
      });
    },
    applyTexture({ item, file }) {
      const canvasId = `canvas-${this.activeTab}`;
      // eslint-disable-next-line no-unused-vars
      const canvas = this.$refs[canvasId][0];
      // Логика применения текстуры к 3D модели с использованием Babylon.js
      console.log(`Применение текстуры ${file} от ${item.name}`);
    },
    logout() {
      // Добавьте логику для обработки выхода
      console.log('Пользователь вышел');
    }
/*    showCreateProjectDialog_func() {
      this.showCreateProjectDialog = true;
    }*/
  },
  mounted() {
    const canvas = this.$refs['canvas-0'][0];
    initializeBabylon(canvas);
  }
};
</script>

<style scoped>
.no-padding {
  padding: 0;
}
.tabbar {
  display: flex;
  align-items: center;
}
.logo {
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
}
.v-tab {
  display: flex;
  align-items: center;
  position: relative;
}
.v-tab .v-icon {
  margin-left: 8px;
  cursor: pointer;
}
.close-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.canvas-container {
  width: 100%;
  height: calc(100vh - 50px); /* Высота за вычетом таббара */
  overflow: hidden; /* Убирает вертикальную прокрутку */
  position: relative;
}
.renderCanvas {
  width: 100% !important; /* Устанавливает ширину на 100% */
  height: 100%;
  display: block; /* Убирает поля у канваса */
}
.full-width {
  width: 100vw; /* Устанавливает ширину на 100vw */
}
.toggle-treeview-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.toggle-leftdrawer-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.user-avatar {
  margin-left: auto;
  cursor: pointer;
}
</style>