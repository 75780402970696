<template>
  <v-app>
    <v-main>
      <BabylonView />
     <router-view />
    </v-main>
  </v-app>
</template>

<script>
import BabylonView from './components/BabylonView.vue';

export default {
  name: 'App',
  components: {
    BabylonView
  }
};
</script>