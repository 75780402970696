import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/projectList',
    name: 'ProjectList',
    component: () => import(/* webpackChunkName: "project-list" */ '../components/ProjectList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/create-project',
    name: 'CreateProject',
    component: () => import(/* webpackChunkName: "create-project" */ '../components/CreateProject.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/upload-image',
    name: 'UploadImage',
    component: () => import(/* webpackChunkName: "upload-image" */ '../components/UploadImage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'LoginForm',
    component: () => import(/* webpackChunkName: "login-form" */ '../components/LoginForm.vue')
  },
  {
    path: '*',
    redirect: '/projectList'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token) {
      next();
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});

export default router;