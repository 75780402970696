// main.js
/* eslint-disable */
import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { UniversalCamera } from "@babylonjs/core/Cameras/universalCamera";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { SkyMaterial } from '@babylonjs/materials/sky';
import { DefaultCollisionCoordinator } from "@babylonjs/core/Collisions/collisionCoordinator";
import { createGlassMaterial, applyTexture } from './textureSettings.js';
import { setSkyMaterialProperties } from './skyboxSettings.js';
import { createActor, setupActorMovement } from './actor.js';

export function initializeBabylon(canvas) {
    const engine = new Engine(canvas, true);
    const scene = new Scene(engine);

    // Camera
    const camera = new UniversalCamera("camera", new Vector3(0, 1.8, -5), scene);
    camera.attachControl(canvas, true);
    camera.applyGravity = true;
    camera.checkCollisions = true;
    camera.ellipsoid = new Vector3(0.5, 1, 0.5);
    camera.speed = 0.1;

    // Enable collisions and gravity
    scene.gravity = new Vector3(0, -0.9, 0);
    scene.collisionsEnabled = true;

    // Lights
    const hemiLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
    hemiLight.intensity = 1.0;
    hemiLight.diffuse = new Color3(1, 1, 1);
    hemiLight.specular = new Color3(0, 0, 0);
    hemiLight.groundColor = new Color3(0.5, 0.5, 0.5);

    // Ground
    const ground = MeshBuilder.CreateGround("ground", { width: 1000, height: 1000 }, scene);
    const groundMaterial = new StandardMaterial("groundMaterial", scene);
    ground.material = groundMaterial;
    ground.checkCollisions = true;

    // Sky Material
    const skyMaterial = new SkyMaterial("skyMaterial", scene);
    skyMaterial.backFaceCulling = false;

    // Skybox
    const skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
    skybox.material = skyMaterial;

    // Glass Material and Mesh
    const glassMaterial = createGlassMaterial(scene, new Color3(1, 1, 1)); // Default white color
    const glassMesh = MeshBuilder.CreateBox("glass", { height: 2, width: 1, depth: 0.003 }, scene);
    glassMesh.material = glassMaterial;
    glassMesh.position.y = 2; // Raise the glass

    // Example usage
    const date = new Date();
    const latitude = 50.4501; // Example latitude (Kyiv, Ukraine)
    const longitude = 30.5234; // Example longitude (Kyiv, Ukraine)
    setSkyMaterialProperties(skyMaterial, date, latitude, longitude);

    // Mannequin/Actor
    const actor = createActor(scene);
    actor.position.z = glassMesh.position.z + 2; // Move actor 2 meters away from the glass
    setupActorMovement(scene, actor, camera);

    // Update camera position to follow the actor (first-person view)
    scene.onBeforeRenderObservable.add(() => {
        camera.position = actor.position.clone();
        camera.position.y += 1.6; // Adjust for the height of the eyes
    });

    // Rendering loop
    engine.runRenderLoop(() => {
        scene.render();
    });

    window.addEventListener('resize', () => {
        engine.resize();
    });

    return { scene, skyMaterial }; // Return skyMaterial
}