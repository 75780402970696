/* eslint-disable no-unused-vars */
import { StandardMaterial, Texture } from "@babylonjs/core/Materials";
import { Color3 } from "@babylonjs/core/Maths/math.color";

export function createGlassMaterial(scene, color) {
    const glassMaterial = new StandardMaterial("glassMat", scene);
    glassMaterial.alpha = 0.5; // Полупрозрачный
    glassMaterial.diffuseColor = color;
    return glassMaterial;
}

export function applyTexture(glassMesh, texture) {
    const { path } = texture;

    const textureImage = new Texture(path, glassMesh.getScene());
    glassMesh.material.diffuseTexture = textureImage;
}