<template>
  <v-navigation-drawer v-model="localLeftDrawer" app class="drawer-left" @update:localLeftDrawer="emitUpdate">
    <v-tabs v-model="activeTab" grow>
      <v-tab>Настройки</v-tab>
      <v-tab>Окружение</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Настройки</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Add your settings controls here -->
        </v-list>
      </v-tab-item>

      <v-tab-item>
        <v-form>
          <v-text-field
            v-model="latitude"
            label="Широта"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="longitude"
            label="Долгота"
            type="number"
          ></v-text-field>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Дата и время"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="menu = false">OK</v-btn>
            </v-date-picker>
          </v-menu>

          <v-slider
            v-model="localSkyMaterial.luminance"
            :max="1"
            :min="0"
            :step="0.01"
            label="Luminance"
            @input="updateSkybox"
          ></v-slider>
          <v-slider
            v-model="localSkyMaterial.turbidity"
            :max="20"
            :min="0"
            :step="1"
            label="Turbidity"
            @input="updateSkybox"
          ></v-slider>
          <v-slider
            v-model="localSkyMaterial.inclination"
            :max="1"
            :min="0"
            :step="0.01"
            label="Inclination"
            @input="updateSkybox"
          ></v-slider>

          <v-btn @click="updateSkybox">Обновить скайбокс</v-btn>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-navigation-drawer>
</template>

<script>
import { setSkyMaterialProperties } from '../babylon/skyboxSettings';

export default {
  props: {
    leftDrawer: Boolean,
    skyMaterial: Object
  },
  data() {
    return {
      localLeftDrawer: this.leftDrawer,
      activeTab: 0,
      latitude: 50.4501,
      longitude: 30.5234,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      localSkyMaterial: { ...this.skyMaterial } // Create a local copy of skyMaterial
    };
  },
  watch: {
    leftDrawer(newVal) {
      this.localLeftDrawer = newVal;
    },
    skyMaterial(newVal) {
      this.localSkyMaterial = { ...newVal }; // Update local copy when prop changes
    }
  },
  methods: {
    emitUpdate(value) {
      this.$emit('update:leftDrawer', value);
    },
    updateSkybox() {
      const date = new Date(this.date);
      const latitude = parseFloat(this.latitude);
      const longitude = parseFloat(this.longitude);

      Object.assign(this.skyMaterial, this.localSkyMaterial); // Apply local changes to the original skyMaterial

      setSkyMaterialProperties(this.skyMaterial, date, latitude, longitude);
    }
  }
};
</script>

<style scoped>
.drawer-left {
  max-width: 300px;
}
</style>