import { render, staticRenderFns } from "./BabylonView.vue?vue&type=template&id=2575e963&scoped=true"
import script from "./BabylonView.vue?vue&type=script&lang=js"
export * from "./BabylonView.vue?vue&type=script&lang=js"
import style0 from "./BabylonView.vue?vue&type=style&index=0&id=2575e963&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2575e963",
  null
  
)

export default component.exports